<template>
    <div>
        <errors-bag-list></errors-bag-list>

        <b-card no-body class='mb-1 text-center'>
            <b-card-header header-tag="header" class="p-2">
                <b-row align-v="center">
                    <b-col md='2'>
                        <date-time-picker
                            locale='ru'
                            label='Месяц'
                            input-size='sm'
                            position='bottom'
                            id='salary_bonus_month_filter'

                            :no-label='true'
                            :no-header='true'
                            :only-date='true'
                            :no-button='true'
                            :auto-close='true'
                            :no-clear-button='true'

                            format='YYYY-MM'
                            formatted='YYYY-MM'

                            v-model='filters.date'
                        ></date-time-picker>
                    </b-col>
                    <b-col md='3'>
                        <work-position-select v-model='filters.work_position' style='display:inline-block;'>
                        </work-position-select>
                    </b-col>
                    <b-col md='7'>
                        <div class='pull-right'>
                            <b-button-group>
                                <b-button variant="warning" @click='openSalaryBonusStandardModal' >Норма балов по проверке</b-button>
                                <b-button variant='success' :to='routerSalaryBonusCreate()'>Создать настройку</b-button>
                                <b-button variant='primary' @click='applyFilters()'>Обновить</b-button>
                            </b-button-group>
                        </div>
                    </b-col>
                </b-row>
            </b-card-header>

            <template v-for='type in types' v-if='types.length > 0'>
                <salary-bonus-table v-model='items' :type='type' @delete-salary-bonus='fetchSalaryBonuses()'>
                </salary-bonus-table>
            </template>
        </b-card>
    </div>
</template>

<script>
    import moment from 'moment';

    import ErrorsBagList from '../../_common/ErrorsBagList';
    import WorkPositionSelect from '../../_common/WorkPositionSelect';

    import ErrorsBag from '../../../utils/errorsBag';
    import {SALARY_ENDPOINT} from '@utils/endpoints';

    import SalaryBonusTable from './SalaryBonusTable';
    import SalaryBonusStandardModal from "@components/salary/bonus/SalaryBonusStandardModal";

    import DateTimePicker from 'vue-ctk-date-time-picker';

    import lsfMixin from '../../../utils/localStorageFilterMixin';

    const FILTERS_PATTERN = {
        work_position: null,
        date: moment().format( 'YYYY-MM' )
    };

    export default {
        name: "SalaryBonusList",
        mixins: [ lsfMixin ],
        components: { ErrorsBagList, WorkPositionSelect, SalaryBonusTable, DateTimePicker, SalaryBonusStandardModal },
        data() {
            return {
                moment,
                ErrorsBag,

                types: [],
                items: []
            };
        },
        watch: {
            filters: {
                deep: true,
                handler( value ) {
                    this.watchFilters( value );
                }
            },
        },
        methods: {
            openSalaryBonusStandardModal() {
                this.$modal.show(SalaryBonusStandardModal, {}, {height: 'auto'})
            },
            routerSalaryBonusCreate() {
                return { name: 'SalaryBonusCreate' };
            },

            fetchSalaryBonusCalculationTypes() {
                this.types = [];

                let params = { with: [ 'attributes' ] };

                return this.$http
                    .get( SALARY_ENDPOINT + '/bonus/calculation-types', { params } )
                    .then(
                        response => this.types = response.data.data.data,
                        response => ErrorsBag.fill( response.body )
                    );
            },
            fetchSalaryBonuses() {
                let filters = this.getFilters;

                let params = {
                    filters: {
                        date: moment( filters.date ).format( 'YYYY-MM' ),
                        work_position: filters.work_position ? { id: filters.work_position.id } : null,
                    },
                };

                this.$http
                    .get( SALARY_ENDPOINT + '/bonus/list', { params } )
                    .then( response => this.items = response.data.data, response => ErrorsBag.fill( response.body ) );
            },

            applyFilters() {
                ErrorsBag.discard();

                this
                    .fetchSalaryBonusCalculationTypes()
                    .then( () => this.fetchSalaryBonuses() );
            }
        },
        created() {
            this.initFilters( this, FILTERS_PATTERN );
        }
    }
</script>
