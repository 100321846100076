<template>
    <b-card
        title="Управление настройками KPI сан. норм"
        @hidden='hideModal'
        ref='SalaryBonusStandardModal'
        id='salary-bonus-standard-modal'
    >
        <errors-bag-list></errors-bag-list>

        <b-table-simple>
            <b-thead head-variant="dark">
                <b-tr>
                    <b-th>Норма</b-th>
                    <b-th>Дата начала</b-th>
                    <b-th>Действия</b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <template v-if="items.length > 0">
                    <b-tr v-for="item in items">
                        <b-th>
                            <b-form-input v-model="item.standard" type="number"></b-form-input>
                        </b-th>
                        <b-th>
                            <date-picker
                                lang="ru"
                                value-type="YYYY-MM-DD"
                                placeholder="Дата"
                                v-model="item.date"
                            />
                        </b-th>
                        <b-th>
                            <b-button-group>
                                <b-button variant="primary" @click="saveSanitaryStandard(item)">Изменить</b-button>
                                <b-button variant="danger" @click="deleteSanitaryStandard(item)">Удалить</b-button>
                            </b-button-group>
                        </b-th>
                    </b-tr>
                </template>
                <template v-else>
                    <b-tr>
                        <b-th colspan="3">
                            Пока что ничего нет
                        </b-th>
                    </b-tr>
                </template>
                <b-tr>
                    <b-th><b-form-input v-model="newItem.standard"></b-form-input></b-th>
                    <b-th>
                        <date-picker
                            lang="ru"
                            value-type="YYYY-MM-DD"
                            placeholder="Дата"
                            v-model="newItem.date"
                        />
                    </b-th>
                    <b-th>
                        <b-button-group>
                            <b-button variant="success" @click="createSanitaryStandard">Добавить</b-button>
                        </b-button-group>
                    </b-th>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <template #modal-footer>
            <div class="w-100">
                <div class='pull-right'>
                    <b-button variant="danger" size="lg" @click="hideModal">Закрыть</b-button>
                </div>
            </div>
        </template>
    </b-card>
</template>

<script>
import ErrorsBag from "@utils/errorsBag";
import {SALARY_ENDPOINT} from "@utils/endpoints";

import ErrorsBagList from "@components/_common/ErrorsBagList";
import DatePicker from 'vue2-datepicker';

import cloneDeep from "lodash/cloneDeep";
import moment from "moment";


const DATE_FORMAT = 'YYYY-MM-DD';

const NEW_ITEM_PATTERN = {
    standard: 0,
    date: null
};

export default {
    name: "SalaryBonusStandardModal",
    components: {
        ErrorsBagList,
        DatePicker,
    },
    data() {
        return {
            ErrorsBag,

            newItem: cloneDeep( NEW_ITEM_PATTERN ),
            items: [],
        };
    },
    methods: {
        hideModal() {
            this.$refs.SalaryBonusStandardModal.hide();
            this.clearModal();
        },
        clearModal() {
            ErrorsBag.discard();

            this.items = [];
            this.newItem = cloneDeep( NEW_ITEM_PATTERN );
        },
        fetchSanitaryStandards() {
            this.clearModal();

            let params = {
                sanitary_standard: { id: this.sanitaryStandard ? this.sanitaryStandard.id : null },
                orders: {date: 'desc'}
            };

            return this.$http
                .get( SALARY_ENDPOINT + '/bonus/sanitary-standards', { params } )
                .then( response => this.items = response.data.data.data, response => ErrorsBag.fill( response.body ) );
        },

        createSanitaryStandard() {
            ErrorsBag.discard();

            let params = {
                standard: parseFloat( this.newItem.standard ).toFixed( 2 ),
                date: this.newItem.date ? moment( this.newItem.date ).format( DATE_FORMAT ) : null
            };

            this.$http
                .post( SALARY_ENDPOINT + '/bonus/sanitary-standards', params )
                .then( () => this.fetchSanitaryStandards(), response => ErrorsBag.fill( response.body ) );
        },
        saveSanitaryStandard( record ) {
            ErrorsBag.discard();

            let params = {
                standard: parseFloat( record.standard ).toFixed( 2 ),
                date: record.date ? moment( record.date ).format( DATE_FORMAT ) : null
            };

            this.$http
                .put( SALARY_ENDPOINT + '/bonus/sanitary-standards/' + record.id, params )
                .then( () => this.fetchSanitaryStandards(), response => ErrorsBag.fill( response.body ) );
        },
        deleteSanitaryStandard( record ) {
            ErrorsBag.discard();

            if ( confirm( 'Вы уверены, что хотите удалить эту норму KPI?' ) ) {
                this.$http
                    .delete( SALARY_ENDPOINT + '/bonus/sanitary-standards/' + record.id )
                    .then(
                        () => this.fetchSanitaryStandards(),
                        response => ErrorsBag.fill( response.body )
                    );
            }
        },
    },
    created() {
        this.fetchSanitaryStandards()
    }
}
</script>
