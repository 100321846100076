<template>
    <div v-if="type" class="mt-2">
        <b-table :items="items" :fields="fields" head-variant="dark" responsive>
            <template #cell(actions)="data">
                <b-button-group>
                    <b-button size="sm" variant="warning" :to='routerSalaryBonusEdit( data.item )'>
                        <b-icon-pencil-square/>
                    </b-button>
                    <b-button size="sm" variant="danger" @click='deleteSalaryBonus( data.item )'>
                        <b-icon-trash-fill/>
                    </b-button>
                </b-button-group>
            </template>
        </b-table>
    </div>
</template>

<script>
    import moment from 'moment';
    import forEach from 'lodash/forEach';

    import ErrorsBag from '../../../utils/errorsBag';
    import { SALARY_ENDPOINT } from '@utils/endpoints';

    const RESPONSIBILITY_TYPE_FIELD = { key: 'responsibility_type', label: 'Тип ответственности' };
    const WORK_POSITION_FIELD = { key: 'work_position', label: 'Должность' };
    const VALID_FROM_FIELD = { key: 'valid_from', label: 'Активен с' };
    const ACTIONS_FIELD = { key: 'actions', label: 'Действия' };
    const CHECK_TYPE_FIELD = { key: 'check_type', label: 'Тип проверки' };
    const ADDITION_FIELD = { key: 'addition', label: 'Тип надбавки' };

    export default {
        name: "SalaryBonusTable",
        props: {
            type: { type: Object, default: () => null },
            value: { type: Array, default: () => [] }
        },
        data() {
            return {
                ErrorsBag,

                items: [],
                fields: [],
            };
        },
        watch: {
            value: {
                deep: true,
                handler() {
                    this.items = [];

                    forEach( this.value, item => {
                        if ( item.calculation_type && item.calculation_type.id !== this.type.id ) {
                            return true;
                        }

                        let attributes = {};
                        forEach(item.config, option => {
                                if (option.attribute) {
                                    attributes[option.attribute.key] = parseFloat(option.value).toFixed(2)
                                }
                            }
                        );

                        let validFrom = moment( item.valid_from );

                        let preparedItem = {
                            id: item.id,
                            ...attributes,
                            work_position: item?.work_position?.name,
                            valid_from: validFrom.format( 'MM.YYYY' ),
                            responsibility_type: item?.responsibility_type?.name,
                            actions: moment().startOf( 'day' ).isBefore( validFrom ),
                            check_type: item?.salary_bonus_check_type?.name,
                            addition: item?.salary_bonus_addition?.name,
                        };

                        this.items.push( preparedItem );
                    } );
                }
            }
        },
        methods: {
            routerSalaryBonusEdit( record ) {
                return { name: 'SalaryBonusEdit', params: { id: record.id } };
            },

            deleteSalaryBonus( record ) {
                ErrorsBag.discard();

                if ( confirm( 'Вы уверены, что хотите удалить эту настройку?' ) ) {
                    this.$http
                        .delete( SALARY_ENDPOINT + '/bonus/' + record.id )
                        .then(
                            () => this.$emit( 'delete-salary-bonus', null ),
                            response => ErrorsBag.fill( response.body )
                        );
                }
            },

            generateFields() {
                let attributes = this.type.attributes;

                this.fields.push( RESPONSIBILITY_TYPE_FIELD );
                this.fields.push( WORK_POSITION_FIELD );
                this.fields.push( VALID_FROM_FIELD );

                forEach( attributes, attribute => {
                    let field = { [ attribute.key ]: attribute.name };

                    this.fields.push( field );
                } );

                if (this.type.key === 'kpi_sanitary_rate') {
                    this.fields.push(CHECK_TYPE_FIELD)
                    this.fields.push(ADDITION_FIELD)
                }

                this.fields.push( ACTIONS_FIELD );
            }
        },
        mounted() {
            this.generateFields();
        }
    }
</script>
